<template>
  <div class="agb">
    <p
      v-t="{ path: 'components.rewards.agb' }"
      class="click-title"
      @click="show = true"
    />
    <Modal v-if="show" :large="true" :long="true" class="terms">
      <template #content>
        <span>
          <p class="terms-title">
            {{ $t('components.rewards.agb') }}
          </p>
          <div class="mt-2 -ml-2 terms-content">
            <client-only>
              <iframe
                class="h-full"
                src="https://www.vienna.at/features/vienna-agb?noheader=1"
              />
            </client-only>
          </div>
          <EssentialsDefaultButtonEvent @click="show = false">
            {{ $t('buttons.ok') }}
          </EssentialsDefaultButtonEvent>
        </span>
      </template>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.agb {
  @apply w-2/3 mx-1 cursor-pointer;
}

.click-title {
  @apply py-1 text-theme-primary text-center border border-theme-primary border-solid rounded-full;
}

.terms-title {
  @apply text-2xl text-theme-primary font-theme-title;
}

@media (min-height: 645px) {
  .terms-content {
    height: 25rem !important;
  }
}
.terms-content {
  @apply mb-4;
  height: 22rem;
  overflow-x: scroll;
}
</style>
